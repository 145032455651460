import PropTypes from "prop-types";

const ApplePayIcon = ({ width = 41, height = 24, fill = "none", ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 41 24"
		fill={fill}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.49147 6.19172C7.01095 6.76594 6.24211 7.21884 5.47327 7.15414C5.37717 6.37774 5.75358 5.55281 6.19406 5.04329C6.67458 4.4529 7.5155 4.03235 8.19624 4C8.27633 4.80875 7.96399 5.60133 7.49147 6.19172ZM8.18823 7.3078C7.07502 7.2431 6.12198 7.94672 5.5934 7.94672C5.05682 7.94672 4.24794 7.34015 3.36698 7.35633C2.22173 7.3725 1.15657 8.02759 0.571931 9.07089C-0.629378 11.1575 0.259591 14.2469 1.42086 15.9453C1.98948 16.7864 2.67022 17.7084 3.5672 17.676C4.41612 17.6437 4.75249 17.118 5.77761 17.118C6.81073 17.118 7.10705 17.676 8.00403 17.6598C8.93304 17.6437 9.51768 16.8187 10.0863 15.9776C10.735 15.0233 10.9993 14.0932 11.0153 14.0447C10.9993 14.0285 9.22136 13.3411 9.20534 11.2707C9.18932 9.53996 10.6069 8.71503 10.6709 8.66651C9.87007 7.46955 8.6207 7.34015 8.18823 7.3078ZM14.6192 4.96242V17.5709H16.5574V13.2602H19.2403C21.691 13.2602 23.4128 11.5618 23.4128 9.10324C23.4128 6.64462 21.723 4.96242 19.3044 4.96242H14.6192ZM16.5574 6.61227H18.7918C20.4736 6.61227 21.4347 7.51808 21.4347 9.11132C21.4347 10.7046 20.4736 11.6185 18.7838 11.6185H16.5574V6.61227ZM26.9527 17.6679C28.17 17.6679 29.2992 17.0452 29.8118 16.0585H29.8518V17.5709H31.6458V11.295C31.6458 9.47526 30.2042 8.30257 27.9858 8.30257C25.9276 8.30257 24.4059 9.49144 24.3498 11.1251H26.0958C26.2399 10.3487 26.9527 9.8392 27.9298 9.8392C29.115 9.8392 29.7798 10.3972 29.7798 11.4244V12.1199L27.3611 12.2655C25.1107 12.4029 23.8934 13.333 23.8934 14.9505C23.8934 16.5842 25.1507 17.6679 26.9527 17.6679ZM27.4733 16.1717C26.4401 16.1717 25.7834 15.6703 25.7834 14.902C25.7834 14.1094 26.4161 13.6484 27.6254 13.5756L29.7798 13.4382V14.1499C29.7798 15.3306 28.7867 16.1717 27.4733 16.1717ZM34.0404 21C35.9305 21 36.8194 20.2721 37.5963 18.0642L41 8.42388H39.0299L36.7474 15.8725H36.7073L34.4248 8.42388H32.3986L35.6822 17.6032L35.506 18.1613C35.2097 19.1075 34.7292 19.4715 33.8722 19.4715C33.7201 19.4715 33.4237 19.4553 33.3036 19.4391V20.9515C33.4157 20.9838 33.8963 21 34.0404 21Z"
			fill="white"
		/>
	</svg>
);

ApplePayIcon.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fill: PropTypes.string,
};

export default ApplePayIcon;
