import PropTypes from "prop-types";

const GooglePayIcon = ({ width = 42, height = 24, fill = "none", ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 42 24"
		fill={fill}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_6611_29653)">
			<path
				d="M19.8581 12.6393V17.5212H18.2831V5.44771H22.3781C23.3756 5.44771 24.3206 5.81516 25.0556 6.49758C25.7906 7.1275 26.1581 8.07238 26.1581 9.06975C26.1581 10.0671 25.7906 10.9595 25.0556 11.6419C24.3206 12.3243 23.4281 12.6918 22.3781 12.6918L19.8581 12.6393ZM19.8581 6.91752V11.117H22.4831C23.0606 11.117 23.6381 10.907 24.0056 10.4871C24.8456 9.69968 24.8456 8.38734 24.0581 7.59994L24.0056 7.54744C23.5856 7.1275 23.0606 6.86503 22.4831 6.91752H19.8581Z"
				fill="white"
			/>
			<path
				d="M29.7806 9.01726C30.9356 9.01726 31.8281 9.33222 32.5106 9.96214C33.1931 10.5921 33.5081 11.432 33.5081 12.4818V17.5212H32.0381V16.3663H31.9856C31.3556 17.3112 30.4631 17.7837 29.4131 17.7837C28.5206 17.7837 27.7331 17.5212 27.1031 16.9963C26.5256 16.4713 26.1581 15.7364 26.1581 14.949C26.1581 14.1091 26.4731 13.4267 27.1031 12.9018C27.7331 12.3768 28.6256 12.1669 29.6756 12.1669C30.6206 12.1669 31.3556 12.3243 31.9331 12.6918V12.3243C31.9331 11.7994 31.7231 11.2745 31.3031 10.9595C30.8831 10.5921 30.3581 10.3821 29.7806 10.3821C28.8881 10.3821 28.2056 10.7495 27.7331 11.4845L26.3681 10.6446C27.2081 9.5422 28.3106 9.01726 29.7806 9.01726ZM27.7856 15.0015C27.7856 15.4215 27.9956 15.7889 28.3106 15.9989C28.6781 16.2614 29.0981 16.4188 29.5181 16.4188C30.1481 16.4188 30.7781 16.1564 31.2506 15.6839C31.7756 15.2115 32.0381 14.6341 32.0381 14.0041C31.5656 13.6367 30.8831 13.4267 29.9906 13.4267C29.3606 13.4267 28.8356 13.5842 28.4156 13.8992C27.9956 14.1616 27.7856 14.5291 27.7856 15.0015Z"
				fill="white"
			/>
			<path
				d="M42.0131 9.27973L36.8156 21.1957H35.2406L37.1831 17.0488L33.7706 9.33222H35.4506L37.9181 15.264H37.9706L40.3856 9.33222H42.0131V9.27973Z"
				fill="white"
			/>
			<path
				d="M13.6106 11.5894C13.6106 11.117 13.5581 10.6446 13.5056 10.1721H6.94312V12.8493H10.6706C10.5131 13.6892 10.0406 14.4766 9.30563 14.949V16.6813H11.5631C12.8756 15.474 13.6106 13.6892 13.6106 11.5894Z"
				fill="white"
			/>
			<path
				d="M6.94313 18.3611C8.83313 18.3611 10.4081 17.7312 11.5631 16.6813L9.30563 14.949C8.67563 15.369 7.88813 15.6314 6.94313 15.6314C5.15813 15.6314 3.58313 14.4241 3.05813 12.7443H0.748125V14.5291C1.95563 16.8913 4.31813 18.3611 6.94313 18.3611Z"
				fill="white"
			/>
			<path
				d="M3.05813 12.7443C2.74313 11.9044 2.74313 10.9595 3.05813 10.0671V8.28235H0.748125C-0.249375 10.2246 -0.249375 12.5343 0.748125 14.5291L3.05813 12.7443Z"
				fill="white"
			/>
			<path
				d="M6.94313 7.23248C7.94063 7.23248 8.88563 7.59994 9.62063 8.28235L11.6156 6.2876C10.3556 5.13275 8.67563 4.45033 6.99563 4.50282C4.37063 4.50282 1.90313 5.92015 0.748125 8.28235L3.05813 10.0671C3.53063 8.38734 5.15813 7.23248 6.94313 7.23248Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6611_29653">
				<rect width="42" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

GooglePayIcon.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fill: PropTypes.string,
};

export default GooglePayIcon;
